const RESOURCES_SLIDER = ['core', 'flows', 'streams', 'rules', 'microgateway', 'machinelearning'];
const TOTAL_RESOURCES = RESOURCES_SLIDER.length;
const SLIDER_ACTION_PREV = 'prev';
const SLIDER_ACTION_NEXT = 'next';
const TOOLTIP_COPY = 'Copy';
const TOOLTIP_COPIED = 'Copied!';
const CLASS_SHOWING_COMMAND = 'js-command-current';

document.getElementById('toggle-menu').addEventListener('click', toggleMenu);
const headSectionElem = document.getElementById('head-section');
const navSectionElem = document.getElementById('nav-section');

// bind event listeners for tabs
document.getElementById("web-ui-command").addEventListener('click', showCommand);
document.getElementById("cli-command").addEventListener('click', showCommand);
document.getElementById("go-command").addEventListener('click', showCommand);

document.getElementById("try-flogo").addEventListener('click', tryFlogoNow);
document.getElementById("nav-try-flogo").addEventListener('click', tryFlogoNow);

const resEcosystemBlock = document.getElementById('res-ecosystem');
const resourcesBlock = document.getElementById('resources-block');
const ecosystemHelpTextBlock = document.getElementById('ecosystem-help-text');
const ecosystemBgAnimationElem = document.getElementById("ecosystem-bg-animation");
const aboutResContainer = document.getElementById('about-resources-container');
const aboutResElem = document.getElementById('about-resources');
aboutResElem.addEventListener('swiped-left', () => resourceSlider(SLIDER_ACTION_NEXT));
aboutResElem.addEventListener('swiped-right', () => resourceSlider(SLIDER_ACTION_PREV));

const copyBtn = document.getElementById('copy-btn');
const copyTooltip = document.getElementById('copy-tooltip');
let copyTooltipTimeOut = null;
copyBtn.addEventListener('click', copyCommand);
copyBtn.addEventListener('mouseenter', showCopyTooltip);
copyBtn.addEventListener('mouseleave', hideCopyTooltip);

// carousel click action listeners
const sliderPrevArrowElement = document.getElementById("slider-prev");
const sliderNextArrowElement = document.getElementById("slider-next");
sliderPrevArrowElement.addEventListener('click', () => resourceSlider(SLIDER_ACTION_PREV));
sliderNextArrowElement.addEventListener('click', () => resourceSlider(SLIDER_ACTION_NEXT));

function bindEcosystemEventListeners() {
    const resources = document.querySelectorAll('#res-outline g');
    for (let resource of resources) {
        resource.addEventListener('click', updateActiveResource);
    }
}
bindEcosystemEventListeners();

function enableEcosystemAnimation(targetElem) {
    if (window.IntersectionObserver) {
        let animationTimout = null;
        const observer = new IntersectionObserver(function(entries) {
            if(entries[0].isIntersecting) {
                animationTimout = setTimeout(() => {
                    targetElem.classList.add('animate-ecosystem');
                }, 500);
            } else {
                clearTimeout(animationTimout);
            }
        }, { threshold: 0.9 });
        observer.observe(targetElem);
    }
}
enableEcosystemAnimation(resourcesBlock);

function isSmallWindow() {
    return window.innerWidth < 768;
}

function isLargeWindow() {
    return window.innerWidth > 999;
}

function makeHeadersticky() {
    if (isSmallWindow()) {
        return;
    }
    if (window.pageYOffset > navSectionElem.offsetHeight) {
        navSectionElem.classList.add('nav-sticky');
    } else {
        navSectionElem.classList.remove('nav-sticky');
    }
}

function onWindowResize() {
    if (!isSmallWindow()) {
        // reset nav bar
        navSectionElem.classList.remove('--open');
        headSectionElem.removeAttribute('style');

        // reset ecosystem block
        aboutResElem.removeAttribute('style');
    } else {
        // reset nav bar
        navSectionElem.classList.remove('nav-sticky');

        // reset ecosystem block
        animateSlider(getActiveResIndex());
    }
}

window.addEventListener('scroll', makeHeadersticky);
window.addEventListener('resize', onWindowResize);

// to ensure header sticky if page is reloaded
makeHeadersticky();

function toggleMenu() {
    navSectionElem.classList.toggle('--open');
    let navHeight = navSectionElem.offsetHeight;
    let repeat = setInterval(function () {
        if (navSectionElem.offsetHeight === navHeight) {
            clearInterval(repeat);
            if (!navSectionElem.classList.contains('--open')) {
                headSectionElem.removeAttribute('style');
            }
            return;
        }
        navHeight = navSectionElem.offsetHeight;
        headSectionElem.style.marginTop = `${navHeight}px`;
    }, 39);
}

function tryFlogoNow(e) {
    e.preventDefault();
    const getStartedElem = document.getElementById('get-started');
    getStartedElem.scrollIntoView({ behavior: 'smooth' });
    // we need to wait for the smooth scroll to be finished before focusing the target element
    // otherwise the focus event will cancel the scroll animation
    // there's no native way to know when the window has stopped scrolling
    let scrollTimeout = null;
    const done = () => {
        location.hash = e.target.hash;
        getStartedElem.focus();
    };
    window.addEventListener('scroll', function () {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(done, 1000);
    }, {once: true});
}

function updateActiveResource(event) {
    if (isSmallWindow()) {
        return;
    }
    hideEcosystemInfoText();
    stopEcosystemAnimation();
    const resType = event.currentTarget.getAttribute('data-res-type');
    showSelectedResource(resType);
    toggleResSliderIndicator(resType);
}

function hideEcosystemInfoText() {
    if (!isLargeWindow()) {
        return;
    }
    if (!ecosystemHelpTextBlock.classList.contains('fadeOut')) {
        ecosystemHelpTextBlock.classList.add('fadeOut');
    }
}

function stopEcosystemAnimation() {
    const classList = resourcesBlock.classList;
    if (classList.contains('animate-ecosystem') && !classList.contains('animate-ecosystem-none')) {
        classList.add('animate-ecosystem-none');
    }
}

function showSelectedResource(resType) {
    selectedBlockBgFill(resType);
    const removeClass = Array.from(aboutResContainer.classList).filter(className => /^active-resource-/.test(className));
    if (removeClass.length) {
        aboutResContainer.classList.remove(removeClass.join(' '));
    }
    const resIndex = getResIndex(resType);
    if (resIndex === 0) {
        aboutResContainer.classList.add('active-resource-first');
    } else if (resIndex === (TOTAL_RESOURCES - 1)) {
        aboutResContainer.classList.add('active-resource-last');
    }
    const targetContentElem = document.getElementById(`about-${resType}`);
    updateClassAtTarget(targetContentElem, ".fg-ecosystem__abt-resource", "active");
}

function selectedBlockBgFill(resType) {
    const removeClass = Array.from(resEcosystemBlock.classList).filter(className => /^active-resource-/.test(className));
    if (removeClass.length) {
        resEcosystemBlock.classList.remove(removeClass.join(' '));
    }
    resEcosystemBlock.classList.add(`active-resource-${resType}`);
    ecosystemBgAnimationElem.beginElement();
}

function resourceSlider(sliderAction) {
    const activeResIndex = getActiveResIndex();
    let nextRes, nextResIndex;
    if (sliderAction === SLIDER_ACTION_NEXT) {
        nextResIndex = activeResIndex + 1;
        nextRes = RESOURCES_SLIDER[activeResIndex + 1];
    } else {
        nextResIndex = activeResIndex - 1;
        nextRes = RESOURCES_SLIDER[activeResIndex - 1];
    }

    if(!canSlideNext(nextResIndex)) {
        return;
    }

    animateSlider(nextResIndex);
    showSelectedResource(nextRes);
    toggleResSliderIndicator(nextRes);
}

function getActiveResIndex() {
    const activeElem = document.querySelector('.fg-ecosystem__abt-resource.active');
    const activeResType = activeElem.id.split('-')[1];
    return getResIndex(activeResType);
}

function canSlideNext(resIndex) {
    return (resIndex > -1) && (resIndex < TOTAL_RESOURCES);
}

function animateSlider(resIndex) {
    aboutResElem.style.transform = `translateX(${(-100 * resIndex)}%)`;
}

function toggleResSliderIndicator(resType) {
    const targetSliderElem = document.querySelector(`#resources-slider li[data-res-type=${resType}]`);
    updateClassAtTarget(targetSliderElem, "#resources-slider li", "active");
}

function getResIndex(resType) {
    return RESOURCES_SLIDER.indexOf(resType);
}

function showCommand(event) {
    updateClassAtTarget(event.currentTarget, ".tabs .tabs__tab", "tabs__tab--active");
    const commandElem = document.querySelector(`[data-tab-id="${event.currentTarget.id}"]`);
    updateClassAtTarget(commandElem, ".fg-get-start__command", "fg-get-start__command--open", CLASS_SHOWING_COMMAND);
}

function updateClassAtTarget(target, selector, ...classes) {
    const allElems = document.querySelectorAll(selector);
    Array.from(allElems).map(elem => elem.classList.remove(...classes));
    target.classList.add(...classes);
}

function copyCommand() {
    updateCopyTooltip(TOOLTIP_COPIED);
    const currentCommandElm = document.querySelector(`.${CLASS_SHOWING_COMMAND}`);
    const command = currentCommandElm.innerText;
    const inputElemTemp = document.createElement('textarea');
    inputElemTemp.setAttribute('id', 'copy-command');
    inputElemTemp.value = command;
    document.body.appendChild(inputElemTemp);
    inputElemTemp.select();
    document.execCommand("copy");
    document.body.removeChild(inputElemTemp);
}

function updateCopyTooltip(tooltipText) {
    updateCopyTooltipText(tooltipText);
    showCopyTooltip();
    copyTooltipTimeOut = setTimeout(hideCopyTooltip, 2000);
}

function updateCopyTooltipText(tooltipText) {
    const tooltip = document.querySelector('.tooltip .tooltip__text');
    tooltip.innerText = tooltipText;
}

function showCopyTooltip() {
    if(window.innerHeight - copyBtn.getBoundingClientRect().bottom > 90) {
        copyTooltip.classList.add('tooltip-bottom');
    } else {
        copyTooltip.classList.add('tooltip-top');
    }
}

function hideCopyTooltip() {
    const classList = copyTooltip.classList;
    if (classList.contains('tooltip-bottom')) {
        classList.remove('tooltip-bottom');
    } else {
        classList.remove('tooltip-top');
    }
    if (copyTooltipTimeOut) {
        clearTimeout(copyTooltipTimeOut);
        updateCopyTooltipText(TOOLTIP_COPY);
    }
}
